import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import './navbar-interactive.css';


const NavbarInteractive = (props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    document.body.classList.toggle('mobile-menu-open', !isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    document.body.classList.remove('mobile-menu-open');
  };

  useEffect(() => {
    const isMenuOpen = document.body.classList.contains('mobile-menu-open');
    setIsMobileMenuOpen(isMenuOpen);
  }, []);

  const handleLinkClick = () => {
    closeMobileMenu();
  };

  return (
    <div className={`navbar-interactive-container ${props.rootClassName}`}>
      <header data-thq="thq-navbar" className="navbar-interactive-navbar-interactive">
        <Link to="/" className="navbar-interactive-navlink">
          <img alt={props.image_alt} src={props.image_src} className="navbar-interactive-image" />
        </Link>
        <div data-thq="thq-navbar-nav" className="navbar-interactive-desktop-menu">
          <nav className="navbar-interactive-links">
            <Link to="/" className="navbar-interactive-navlink1" onClick={handleLinkClick}>{props.text}</Link>
            <Link to="/about-us" className="navbar-interactive-navlink2" onClick={handleLinkClick}>{props.text1}</Link>
            <Link to="/transparency" className="navbar-interactive-navlink3" onClick={handleLinkClick}>{props.text2}</Link>
            {/* <Link to="/socials" className="navbar-interactive-navlink4" onClick={handleLinkClick}>{props.text3}</Link> */}
            <a href={props.link_text} target="_blank" rel="noreferrer noopener" className="navbar-interactive-link" onClick={handleLinkClick}>{props.text4}</a>
          </nav>
          <div className="navbar-interactive-buttons">
            
            <a href={props.link_Login} target="_blank" rel="noreferrer noopener" className="navbar-interactive-login button">{props.Login}</a>
          </div>
          {/* <div className="navbar-interactive-buttons">
            
            <a href={props.link_Register} target="_blank" rel="noreferrer noopener" className="navbar-interactive-register button">{props.Register}</a>
          </div> */}
        </div>
        <div data-thq="thq-burger-menu" className="navbar-interactive-burger-menu" onClick={toggleMobileMenu}>
        <img src="menu.svg" alt="Menu Icon" className="navbar-interactive-icon02" />
        </div>
        <div data-thq="thq-mobile-menu" className={`navbar-interactive-mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <div className="navbar-interactive-nav">
            <div className="navbar-interactive-top">
              <img alt={props.Logo_alt} src={props.Logo_src} className="navbar-interactive-logo" />
              <div data-thq="thq-close-menu" className="navbar-interactive-close-menu" onClick={closeMobileMenu}>
              <img src="Cross.svg" alt="Menu Icon" className="navbar-interactive-icon02" />
              </div>
            </div>
            <nav className="navbar-interactive-links1">
              <Link to="/" className="navbar-interactive-text" onClick={handleLinkClick}>{props.text}</Link>
              <Link to="/about-us" className="navbar-interactive-text1" onClick={handleLinkClick}>{props.text1}</Link>
              <Link to="/transparency" className="navbar-interactive-text2" onClick={handleLinkClick}>{props.text2}</Link>
              {/* <Link to="/socials" className="navbar-interactive-text3" onClick={handleLinkClick}>{props.text3}</Link> */}
              <a href={props.link_text1} target="_blank" rel="noreferrer noopener" className="navbar-interactive-link1" onClick={handleLinkClick}>{props.text9}</a>
            </nav>
            <div className="navbar-interactive-buttons1">
              <a href={props.link_Login} target="_blank" rel="noreferrer noopener" className="navbar-interactive-login1 button" onClick={handleLinkClick}>{props.Login}</a>
            </div>
            {/* <div className="navbar-interactive-buttons1">
              <a href={props.link_Register1} target="_blank" rel="noreferrer noopener" className="navbar-interactive-register1 button" onClick={handleLinkClick}>{props.Register1}</a>
            </div> */}
          </div>
        </div>
      </header>
    </div>
  );
};

NavbarInteractive.defaultProps = {
  image_src: '/logo-black.svg',
  image_alt: 'logo',
  text4: 'Support',
  link_text: 'https://neonomadhelp.zendesk.com/hc/en-us',
  text6: 'About Us',
  Register1: 'Register',
  Login: 'Join waitlist',
  Logo_src: '/logo-black.svg',
  link_text1: 'https://neonomadhelp.zendesk.com/hc/en-us',
  text8: 'Socials',
  text5: 'Home',
  text9: 'Support',
  text7: 'Transparency',
  text2: 'Transparency',
  Login1: 'Join waitlist',
  text1: 'About Us',
  link_Register: 'https://www.neonomad.io',
  link_Register1: 'https://www.neonomad.io/',
  link_Login: 'https://neonomad.io/signup',
  Register: 'Register',
  text3: 'Socials',
  rootClassName: '',
  Logo_alt: 'image',
  text: 'Home',
};

NavbarInteractive.propTypes = {
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  text4: PropTypes.string,
  link_text: PropTypes.string,
  text6: PropTypes.string,
  Register1: PropTypes.string,
  Login: PropTypes.string,
  Logo_src: PropTypes.string,
  link_text1: PropTypes.string,
  text8: PropTypes.string,
  text5: PropTypes.string,
  text9: PropTypes.string,
  text7: PropTypes.string,
  text2: PropTypes.string,
  Login1: PropTypes.string,
  text1: PropTypes.string,
  link_Register: PropTypes.string,
  link_Register1: PropTypes.string,
  Register: PropTypes.string,
  text3: PropTypes.string,
  rootClassName: PropTypes.string,
  Logo_alt: PropTypes.string,
  text: PropTypes.string,
};

export default NavbarInteractive;
