import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './transparency-tabs.css';

const TransparencyTabs = (props) => {
  const [tabSelector, setTabSelector] = useState(1); // Set initial state to 2

  const handleTabClick = (tabNumber) => {
    setTabSelector(tabNumber);
  };

  const tabData = [
    { index: 1, name: 'ZARC issued', textProp: 'text' },
    { index: 2, name: 'Reports and reserves', textProp: 'text1' },
    { index: 3, name: 'Bridges', textProp: 'text2' },
  ];

  return (
    
    <div className={`transparency-tabs-container ${props.rootClassName}`}>
     
      <div className="transparency-tabs-frame427320453">
        <div className="transparency-tabs-tabs">
          {tabData.map(({ index, name, textProp }) => (
            <div
              key={index}
              onClick={() => handleTabClick(index)}
              className={`transparency-tabs-active transparency-tabs-active${index} ${
                tabSelector === index ? 'active-tab' : ''
              }`}
            >
              <div
                onClick={() => handleTabClick(index)}
                className={`transparency-tabs-tab transparency-tabs-tab${index} ${
                  tabSelector === index ? 'active-tab' : ''
                }`}
              >
                <span
                  onClick={() => handleTabClick(index)}
                  className={`transparency-tabs-text transparency-tabs-text${index} ${
                    tabSelector === index ? 'active-text' : ''
                  }`}
                >
                  <span className="">{name}</span>
                </span>
              </div>
            </div>
          ))}
        </div>
        {tabSelector === 1 && (
          <div className="transparency-tabs-frame427320450">
            <span className="transparency-tabs-text6">{props.text}</span>
          </div>
        )}
        {tabSelector === 2 && (
          <div className="transparency-tabs-frame4273204501">
            <span className="transparency-tabs-text7">{props.text1}</span>
          </div>
        )}
        {tabSelector === 3 && (
          <div className="transparency-tabs-frame4273204502">
            <span className="transparency-tabs-text8">{props.text2}</span>
          </div>
        )}
      </div>
    </div>
  );
};

TransparencyTabs.defaultProps = {
  text: 'Coming soon!',
  text1: 'Coming soon!',
  text2: 'Coming soon!',
  rootClassName: '',
};

TransparencyTabs.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default TransparencyTabs;
