import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarInteractive from '../components/navbar-interactive'
import TransparencyTabs from '../components/transparency-tabs'
import Footer from '../components/footer'
import './transparency.css'

const Transparency = (props) => {
  return (
    <div className="transparency-container">
      <Helmet>
        <title>Transparency - ZARCoin</title>
        <meta property="og:title" content="Transparency - ZARCoin" />
        <meta name="robots" content="noindex" />
      </Helmet>
      
      <div className="transparency-container1">
        <NavbarInteractive rootClassName="navbar-interactive-root-class-name2"></NavbarInteractive>
        <div className='trans-header-ctn'>
        <div className='Header-Transparency'> Transparency</div></div>
        <TransparencyTabs rootClassName="transparency-tabs-root-class-name1"></TransparencyTabs>
        <div className="transparency-ctn-2">
          <Footer rootClassName="footer-root-class-name1"></Footer>
        </div>
      </div>
      <div className="transparency-container2">
        <span className="transparency-text">
          <span>Copyright © 2023</span>
          <span className="transparency-text2">
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
          <span className="transparency-text5">NeoNomad</span>
          <span className="transparency-text6">.</span>
          <span> All rights reserved</span>
          <span>.</span>
        </span>
      </div>
    </div>
  )
}

export default Transparency
