import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import NavbarInteractive from '../components/navbar-interactive'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {

  const [homeText04Data, setHomeText04Data] = useState("$1");
  const [homeText09Data, setHomeText09Data] = useState('$1');
const [homeText14Data, setHomeText14Data] = useState('$1');
const [homeText19Data, setHomeText19Data] = useState('$1');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://public-api.birdeye.so/defi/price?address=Eh2Lea2KWLryBQaQEGiJFdRzRPjTeTXaJ4ZaCsfDuAhB', {
          headers: {
            'X-API-KEY': 'fadaf01882af4a6dacc6cc1e760654f4'
          }
        });
        const json = await response.json();
        
        const response2 = await fetch('https://public-api.birdeye.so/defi/price?address=buMnhMd5xSyXBssTQo15jouu8VhuEZJCfbtBUZgRcuW', {
          headers: {
            'X-API-KEY': 'fadaf01882af4a6dacc6cc1e760654f4'
          }
        });
        const json2 = await response2.json();
        
        const response3 = await fetch('https://public-api.birdeye.so/defi/price?address=3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh', {
          headers: {
            'X-API-KEY': 'fadaf01882af4a6dacc6cc1e760654f4'
          }
        });
        const json3 = await response3.json();

        const response4 = await fetch('https://public-api.birdeye.so/defi/price?address=So11111111111111111111111111111111111111112', {
          headers: {
            'X-API-KEY': 'fadaf01882af4a6dacc6cc1e760654f4'
          }
        });

        const json4 = await response4.json();

        // Update the state variable for home-text04 using the API data
        setHomeText04Data(`R${(1 / parseFloat(json.data.value)).toFixed(2)}`);
        setHomeText09Data(`${(parseFloat(json.data.value) / parseFloat(json2.data.value)).toFixed(4)}`);
        setHomeText14Data(`${(parseFloat(json.data.value) / parseFloat(json3.data.value)).toFixed(7)}`);
        setHomeText19Data(`${(parseFloat(json.data.value) / parseFloat(json4.data.value)).toFixed(7)}`);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  
 

  return (
    <div className="home-container">
      <Helmet>
        <title>ZARC - A South African Rand Stablecoin</title>
        <meta property="og:title" content="ZARCoin" />
        {/* <script>
        {`
          (function(h,o,t,j,a,r){
            h.hj=h.hjfunction(){(h.hj.q=h.hj.q[]).push(arguments)};
            h._hjSettings={hjid:3907138,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </script> */}
      </Helmet>
      <NavbarInteractive rootClassName="navbar-interactive-root-class-name"></NavbarInteractive>
      <div className="home-container01">
        
        <div className="home-ctn-2">
          <div className="home-headerctn">
          <span className="home-text">
              Money faster than you can say <span className='bank-text'>B.A.N.K</span>
            </span>
            <div className="home-container02">
              <span className="home-text01">
                The Stablecoin that will disrupt the South African Financial
                Industry. 100% backed, transparent, and fully compliant. A
                Financial EcoSystem that never sleeps!
              </span>
            </div>
            <div className="home-container03">
           

  <Link to="https://neonomad.io/signup" className="home-navlink button" target="_blank">
    Get started
  </Link>
</div>

          </div>
          <div className="home-container04">
            <div className="home-container05">
              <div className="home-container06">
                <div className="home-container07">
                  <img
                    alt="image"
                    src="/usdc-200h.png"
                    className="home-image"
                  />
                  <span className="home-text02">USDC</span>
                </div>
                <div className="home-container08">
                  <div className="home-container09">
                    <span className="home-text03">Latest price</span>
                    <span className="home-text04">{homeText04Data}</span>
                  </div>
                  <div className="home-container10">
                    <span className="home-text05">24h change</span>
                    {/* <span className="home-text06">1.26%</span> */}
                  </div>
                </div>
                <div className="home-container11">
                  <img
                    alt="image"
                    src="/chart1-200h.png"
                    className="home-image01"
                  />
                </div>
              </div>
              <div className="home-container12">
                <div className="home-container13">
                  <img
                    alt="image"
                    src="/logo-14%201%20(2)-200h.png"
                    className="home-image02"
                  />
                  <span className="home-text07">NNI</span>
                </div>
                <div className="home-container14">
                  <div className="home-container15">
                    <span className="home-text08">Latest price</span>
                    <span className="home-text09">{homeText09Data}</span>
                  </div>
                  <div className="home-container16">
                    <span className="home-text10">24h change</span>
                    {/* <span className="home-text11">1.26%</span> */}
                  </div>
                </div>
                <div className="home-container17">
                  <img
                    alt="image"
                    src="/chart1-200h.png"
                    className="home-image03"
                  />
                </div>
              </div>
              <div className="home-container18">
                <div className="home-container19">
                  <img
                    alt="image"
                    src="/bitcoin-logo-color-illustration-png%202%20(1)-200w.png"
                    className="home-image04"
                  />
                  <span className="home-text12">Bitcoin</span>
                </div>
                <div className="home-container20">
                  <div className="home-container21">
                    <span className="home-text13">Latest price</span>
                    <span className="home-text14">{homeText14Data}</span>
                  </div>
                  <div className="home-container22">
                    <span className="home-text15">24h change</span>
                    {/* <span className="home-text16">1.26%</span> */}
                  </div>
                </div>
                <div className="home-container23">
                  <img
                    alt="image"
                    src="/chart1-200h.png"
                    className="home-image05"
                  />
                </div>
              </div>
              <div className="home-container24">
                <div className="home-container25">
                  <img
                    alt="image"
                    src="/solana-sol-logo-12828ad23d-seeklogo%202%20(1)-200h.png"
                    className="home-image06"
                  />
                  <span className="home-text17">Solana</span>
                </div>
                <div className="home-container26">
                  <div className="home-container27">
                    <span className="home-text18">Latest price</span>
                    <span className="home-text19">{homeText19Data}</span>
                  </div>
                  <div className="home-container28">
                    <span className="home-text20">24h change</span>
                    {/* <span className="home-text21">1.26%</span> */}
                  </div>
                </div>
                <div className="home-container29">
                  <img
                    alt="image"
                    src="/chart1-200h.png"
                    className="home-image07"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-container30">
          <div className="home-container31">
            <span className="home-text22">Driving the future of finance</span>
            <span className="home-text23">
              No middlemen. No excess fees. Transcend Borders
            </span>
            <span className="home-text24">
              Save costs and build your business. empowering the people.
              Available on multiple blockchains (Solana, Binance, Ethereum)
            </span>
          </div>
          <div className="home-container32">
            <div className="home-container33">
              <button type="button" className="home-button button">
                <span className="home-text25">
               <a href='https://docs.neonomad.io/nni/tokenomics-fundamentals/whitepapers/zarc-whitepaper'>
                  <span>View whitepaper</span>
              
                  <br></br>
                  <br></br>
                  </a>
                </span>
              </button>
              {/* <button type="button" className="home-button1 button">
                <span className="home-text29">
               
                  <span>For Retailers</span>
                  
                  <br></br>
                  <br></br>
                </span>
              </button> */}
              {/* <button type="button" className="home-button2 button">
                <span className="home-text33">
              
                  <span>For Institutions</span>
                 

                  <br></br>
                  <br></br>
                  <br></br>
                </span>
              </button> */}
            </div>
          </div>
        </div>
        <div className="home-container34">
          <div className="home-container35">
            <span className="home-text38">
              <span className="home-text39">
                100% Backed and fully transparent
              </span>
              <br></br>
              <br></br>
            </span>
            <span className="home-text42">
              Each ZARC issued into circulation will be backed in a 1:1 ratio
              with the equivalet amount of corresponding fiat currency held in
              reserves by South African based ZARC (PTY) LTD. As the custodian
              of the backing asset we are acting as a trusted third party
              responisble for that asset. This risk is mitigated by a simple
              implementation that collectively reduces the complexity of
              conducting both fiat and crypto audits while increasing the
              security, provability and transparency of these audits.
            </span>
          </div>
        </div>
        <div className="home-gallery">
          <div className="home-gallery-card">
            <div className="home-container36">
              <img alt="image" src="/6.svg" className="home-image08" />
              <div className="home-container37">
                <span className="home-text43">
                  <span>Unparalleled liquidity</span>
                  <br></br>
                  <br></br>
                </span>
                <span className="home-text47">
                  ZARC, Africa&apos;s first true stablecoin, fuels a thriving
                  ecosystem with unparalleled liquidity and unbeatable rates.
                  Our robust, reserve-backed liquidity solutions ensure security
                  and resilience for seamless transactions.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="home-gallery-card1">
            <div className="home-container38">
              <img alt="image" src="/4.svg" className="home-image09" />
              <div className="home-container39">
                <span className="home-text48">
                  <span>Proof of Reserves</span>
                  <br></br>
                  <br></br>
                </span>
                <span className="home-text52">
                  ZARC ensures the utmost integrity of reserves, with a 1:1
                  reserve ratio between ZARC and the South African Rand.
                  Publicly available audits and transparent overviews provide
                  optimal accountability and stability
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="home-gallery-card2">
            <div className="home-container40">
              <img alt="image" src="/5.svg" className="home-image10" />
              <div className="home-container41">
                <span className="home-text53">
                  <span>Regulatory Compliant</span>
                  <br></br>
                  <br></br>
                </span>
                <span className="home-text57">
                ZARC: Trust Built on Compliance. Anti-Money Laundering (AML), Countering the Financing of Terrorism (CFT), Sanctions, 
                Know Your Customer (KYC), and Privacy Regulations Ensure a Trustworthy Ecosystem.
                </span>
              </div>
            </div>
          </div>
          <div className="home-gallery-card3">
            <div className="home-container42">
              <img alt="image" src="/2.svg" className="home-image11" />
              <div className="home-container43">
                <span className="home-text58">
                  <span>Multiple blockchain support</span>
                  <br></br>
                  <br></br>
                </span>
                <span className="home-text62">
                  ZARC: Seamless Multi-Chain Access. Issued on Solana, Binance
                  Smart Chain, and Ethereum. Expandable to more EVM chains,
                  unlocking the potential of diverse blockchain networks.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="home-gallery-card4">
            <div className="home-container44">
              <img
                alt="image"
                src="/3.svg"
                className="home-image12"
              />
              <div className="home-container45">
                <span className="home-text63">
                  <span>Customer Support</span>
                  <br></br>
                  <br></br>
                </span>
                <span className="home-text67">
                  ZARC: Instant Support. Our dedicated team, powered by Zendesk,
                  is available 24/7 for swift assistance. Connect with us via
                  live chat or ticketing.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <Footer rootClassName="footer-root-class-name3"></Footer>
        <div className="home-container46">
          <span className="home-text68">
            <span>Copyright © 2023</span>
            <span className="home-text70">
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text71">NeoNomad</span>
            <span className="home-text72">.</span>
            <span> All rights reserved</span>
            <span>.</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Home
