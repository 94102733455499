import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-container">
        <div className="footer-container01">
          <div className="footer-container02">
            <div className="footer-logo">
              <div className="footer-container03">
                <div className="footer-container04"></div>
              </div>
              <img
                alt={props.image_alt}
                src="/logo-black.svg"
                className="footer-image"
              />
              <span className="footer-text">{props.text}</span>
              <div className='footer-container123'><div className="social-block1">
    <a href="https://www.facebook.com/neonomadfinance" className="social-link">
      <img src="facebook.svg" alt="Social Icon 1" className="social-icon" />
    </a>
  </div>
  <div className="social-block1">
    <a href="https://www.instagram.com/neonomad.xchange/" className="social-link">
      <img src="instagram.svg" alt="Social Icon 2" className="social-icon" />
    </a>
  </div>
  <div className="social-block1">
    <a href="https://twitter.com/NeoNomadFinance" className="social-link">
      <img src="twitter.svg" alt="Social Icon 2" className="social-icon" />
    </a>
  </div>
  <div className="social-block1">
    <a href="https://www.youtube.com/channel/UC_xQFH_TCyBH-Miy76VaWDQ" className="social-link">
      <img src="youtube.svg" alt="Social Icon 2" className="social-icon" />
    </a>
  </div>
  <div className="social-block1">
    <a href="https://www.linkedin.com/company/neonomad-finance" className="social-link">
      <img src="linkedin.svg" alt="Social Icon 2" className="social-icon" />
    </a>
  </div>
  <div className="social-block1">
    <a href="https://t.me/neonomadfinance" className="social-link">
      <img src="telegram.svg" alt="Social Icon 2" className="social-icon" />
    </a>
  </div>
  
  </div>
            </div>
          </div>
          <div className="footer-links-container">
            <div className="footer-container05">
              <div className="footer-product-container">
                <span className="footer-text01">{props.text1}</span>
                <div className="footer-container06"></div>
                <Link to="/about-us" className="footer-navlink">
                  <span className="">About us</span>
                  <br className=""></br>
                </Link>
                <Link to="/transparency" className="footer-navlink1">
                  <span className="">Transparency</span>
                  <br className=""></br>
                </Link>
                {/* <Link to="/socials" className="footer-navlink2">
                  <span className="">Socials</span>
                  <br className=""></br>
                </Link> */}
                <a
                  href={props.link_text}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link"
                >
                  {props.text2}
                </a>
              </div>
            </div>
            <div className="footer-company-container">
              <span className="footer-text08">
                <span className="">Blog Posts</span>
                <br className=""></br>
              </span>
              <div className="footer-container07"></div>
              <span className="footer-text11">
                <a
                  href="https://neonomad.io/general/zarc-stablecoin"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link1"
                >
                  ZARC NeoNomad’s 1:1 pegged South African Rand stablecoin..
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </a>
               
                <br className=""></br>
                <br className="footer-text15"></br>
                <span className="footer-text16">26 Oct 2022</span>
                <br className=""></br>
              </span>
            </div>
            <div className="footer-container08">
              <div className="footer-contact">
                <span className="footer-text18">{props.text3}</span>
                <div className="footer-container09"></div>
                <span className="footer-text19">
                  <span className="footer-text20">South Africa</span>
                  <br className=""></br>
                  <br className=""></br>
                  <span className="">
                    Green Point, Cape Town, Western Cape, South Africa, 8001
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer-separator"></div> */}
    </footer>
  )
}

Footer.defaultProps = {
  link_text: 'https://neonomadhelp.zendesk.com/hc/en-us',
  text1: 'Other Links',
  text: 'ZARC: The Stablecoin Revolutionizing the South African Financial Industry. 100% Backed, Transparent, and Fully Compliant. Join the Financial Ecosystem that Never Sleeps.',
  text3: 'Location',
  image_src: 'b309fb8f-1f1e-406c-bf3c-ba39795fe6dc',
  text2: 'Support',
  rootClassName: '',
  image_alt: 'logo',
}

Footer.propTypes = {
  link_text: PropTypes.string,
  text1: PropTypes.string,
  text: PropTypes.string,
  text3: PropTypes.string,
  image_src: PropTypes.string,
  text2: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
}

export default Footer
