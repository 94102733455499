import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarInteractive from '../components/navbar-interactive'
import Footer from '../components/footer'
import './about-us.css'

const AboutUs = (props) => {
  return (
    <div className="about-us-container">
      <Helmet>
        <title>About-us - ZARCoin</title>
        <meta property="og:title" content="About-us - ZARCoin" />
      </Helmet>
      <div className="about-us-container01">
        <NavbarInteractive rootClassName="navbar-interactive-root-class-name3"></NavbarInteractive>
        <div className="about-us-ctn-2">
          <div className="about-us-headerctn">
            <span className="about-us-text">About Us</span>
            <div className="about-us-container02">
              <span className="about-us-text01">
                Transfer ZAR, USD, EUR or Crypto and start trading today!
              </span>
            </div>
          </div>
          <div className="about-us-container03">
            <div className="about-us-container04">
              <iframe
                src="https://www.youtube.com/embed/wL8NSlO00Ig"
                allowFullScreen
                className="about-us-iframe"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="about-us-container05">
          <div className="about-us-container06">
            <span className="about-us-text02">
              <span className="about-us-text03">Our mission</span>
              
              <br></br>
            </span>
            <span className="about-us-text06">
              In a world where global wealth is controlled largely by big
              institutions and governments, ZARC aims to provide a more
              decentralised solution that gives power back to the people
            </span>
          </div>
        </div>
        <div className="about-us-gallery">
          <div className="about-us-gallery-card">
            <div className="about-us-container07">
              <img
                alt="image"
                src="/7.svg"
                className="about-us-image"
              />
              <div className="about-us-container08">
                <span className="about-us-text07">
                  <span>Financial stability</span>
                  <br></br>
                  <br></br>
                </span>
                <span className="about-us-text11">
                  ZARC&apos;s commitment to a 1:1 peg with the South African
                  Rand ensures price stability, instilling confidence. Our
                  resilient platform, robust risk management, and effective
                  regulation promote economic growth and financial well-being
                </span>
              </div>
            </div>
          </div>
          <div className="about-us-gallery-card1">
            <div className="about-us-container09">
              <img alt="image" src="/6.svg" className="about-us-image1" />
              <div className="about-us-container10">
                <span className="about-us-text12">
                  <span>Unparalled Liquidity</span>
                  <br></br>
                  <br></br>
                </span>
                <span className="about-us-text16">
                  Leverage ZARC&apos;s unparalleled liquidity for seamless
                  trading across exchanges. Enjoy swift on/off-ramping,
                  effortless fund deposits, and withdrawals, ensuring
                  hassle-free liquid investments for all investors
                </span>
              </div>
            </div>
          </div>
          <div className="about-us-gallery-card2">
            <div className="about-us-container11">
              <img alt="image" src="/4.svg" className="about-us-image2" />
              <div className="about-us-container12">
                <span className="about-us-text17">
                  <span>Exponential growth</span>
                  <br></br>
                  <br></br>
                </span>
                <span className="about-us-text21">
                  ZARC is committed to fostering worldwide economic growth,
                  development, and financial inclusion. Our secure and
                  user-friendly assets support the diverse needs of the global
                  economy, promoting prosperity and accessibility.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="about-us-gallery-card3">
            <div className="about-us-container13">
              <img alt="image" src="/4-200h.png" className="about-us-image3" />
              <div className="about-us-container14">
                <span className="about-us-text22">
                  <span>Fully transparent</span>
                  <br></br>
                  <br></br>
                </span>
                <span className="about-us-text26">
                  ZARC prioritizes the integrity of reserves. Our Proof of
                  Reserves process ensures ZAR Fiat Currency balance in reserves
                  is always equal to or greater than circulating ZARC,
                  solidifying our commitment to transparency and price parity.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="about-us-gallery-card4">
            <div className="about-us-container15">
              <img alt="image" src="/2.svg" className="about-us-image4" />
              <div className="about-us-container16">
                <span className="about-us-text27">
                  <span>Bank to blockchain</span>
                  <br></br>
                  <br></br>
                </span>
                <span className="about-us-text31">
                  Move effortlessly from traditional banking to ZARC&apos;s
                  secure stablecoin ecosystem. Enjoy transparent and auditable
                  transactions, verifying integrity through dedicated tools like
                  Solscan, BSCscan, and ETHscan.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <Footer rootClassName="footer-root-class-name2"></Footer>
        <div className="about-us-container17">
          <span className="about-us-text32">
            <span>Copyright © 2023</span>
            <span className="about-us-text34">
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
            <span className="about-us-text37">NeoNomad</span>
            <span className="about-us-text38">.</span>
            <span> All rights reserved</span>
            <span>.</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
