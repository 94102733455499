import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import './style.css';
import AboutUs from './views/about-us';
import Home from './views/home';
import Socials from './views/socials';
import Transparency from './views/transparency';
import NotFound from './views/not-found';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/" element={<Home />} />
        <Route path="/socials" element={<Socials />} />
        <Route path="/transparency" element={<Transparency />} />
        <Route path="*" element={<NotFound />} />
        {/* Redirect any unmatched route to the NotFound component */}
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
